<template>
  <v-container fluid class="job-teams">
    <v-container v-if="!isVisitDetail">
      <v-layout class="justify-end">
        <div class="mx-2">
          <v-autocomplete
            hide-details
            clearable
            v-model.trim="filter_engineer"
            :items="engineerList"
            dense
            flat
            filled
            label="Technicians"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="pageLoading || engineerLoading"
            :loading="engineerLoading || pageLoading"
            item-text="display_name"
            item-value="id"
            v-on:change="getTimestamp"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Engineer(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-ellipsis max-width-400px">
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.display_name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>

        <div class="ml-2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="ml-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="teamLoading"
                v-on:click="getTimestamp"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
        </div>
      </v-layout>
    </v-container>
    <v-skeleton-loader
      type="text@5"
      class="custom-skeleton table-rows-text"
      v-if="teamLoading"
    ></v-skeleton-loader>
    <v-simple-table
      v-else
      class="inner-simple-table"
      :class="{ 'custom-border-top': !isVisitDetail }"
      fixed-header
    >
      <template v-slot:default>
        <thead>
          <tr class="custom-border-bottom">
            <td class="font-size-16 font-weight-700">#</td>
            <td class="font-size-16 font-weight-700">Technicians</td>
            <td class="font-size-16 font-weight-700">Remarks</td>
            <td class="font-size-16 font-weight-700">Check In Date</td>
            <td class="font-size-16 font-weight-700">Check In Time</td>
            <td class="font-size-16 font-weight-700">Checkout Date</td>
            <td class="font-size-16 font-weight-700">Checkout Time</td>
            <td class="font-size-16 font-weight-700">Total Time</td>
          </tr>
        </thead>
        <tbody>
          <template>
            <tr v-for="(data, index) in timestampList" :key="index">
              <td class="cursor-pointer font-size-16 font-weight-500">
                {{ index + 1 }}
              </td>
              <td
                class="cursor-pointer font-size-16 font-weight-500"
                width="200"
              >
                <template
                  v-if="data && data.engineer && data.engineer.display_name"
                >
                  {{ data.engineer.display_name }}
                </template>
                <template v-else
                  ><em class="text--secondary">No Engineer </em></template
                >
              </td>

              <!-- <td
                class="cursor-pointer font-size-16 font-weight-500"
                width="200"
              >
                <tempalte v-for="(dataremark, indexs) in timestampListRemark" :key="indexs">
                  
                  <tempalte  v-if="dataremark && dataremark.remark_hold">
                     <template v-if="index == indexs">
                        {{ dataremark.remark_hold }}
                     </template>
                  
                  </tempalte>
                   <em class="text-muted" v-else>No remark</em>
                 </tempalte>
              </td> -->

              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.remark_hold_checkout">
                  <span style="white-space: break-spaces">{{
                    data.remark_hold_checkout
                  }}</span>
                </template>
                <template v-else
                  ><em class="text--secondary">No Remarks </em></template
                >
              </td>

              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.date">
                  {{ data.date }}
                </template>
                <template v-else
                  ><em class="text--secondary">No checkin date </em></template
                >
              </td>

              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.checkin_time">
                  {{ data.checkin_time }}
                </template>
                <template v-else
                  ><em class="text--secondary">No CheckIn Time </em></template
                >
              </td>

              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.checkout_date">
                  {{ data.checkout_date }}
                </template>
                <template v-else
                  ><em class="text--secondary">No checkout date </em></template
                >
              </td>
              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.checkout_time">
                  {{ data.checkout_time }}
                </template>
                <template v-else
                  ><em class="text--secondary">No CheckOut Time </em></template
                >
              </td>
              <td class="cursor-pointer font-size-16 font-weight-500">
                <template v-if="data && data.checkout_time && data.total_time">
                  {{ data.total_time }}
                </template>
                <template v-else
                  ><em class="text--secondary">No Total Time </em></template
                >
              </td>
            </tr>
          </template>
          <!-- <template v-else>
              <tr>
                <td :colspan="isVisitDetail ? 4 : 5">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    Sorry! No Engineer(s) Found.
                  </p>
                </td>
              </tr>
            </template> -->
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
//import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { QUERY } from "@/core/services/store/request.module";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    pageLoading: {
      type: Boolean,
      default: false,
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
    visitId: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      dates: [],
      datePicker: false,
      engineerLoading: false,
      // pageLoading: false,
      filter_engineer: null,
      engineerList: [],
      job: 0,
      teams: [],
      visitList: [],
      timestampList: [],
      filter: {
        search: null,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      teamLoading: false,
    };
  },
  components: {
    //Barcode,
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  methods: {
    getTimestamp() {
      this.loadingEngineers = true;
      let filter = {
        engineer: this.lodash.toSafeInteger(this.filter_engineer) || null,
      };
      this.$store

        .dispatch(QUERY, {
          url: "visit/" + this.visitId + "/CheckinCheckout/",
          data: filter,
        })
        .then(({ data }) => {
          this.timestampList = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },
    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list-filter",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getEngineerList();
    },
  },
  mounted() {
    //  this.getEngineerList();
    this.getTimestamp();
    this.getEngineerList();
    // this.getVisitList();

    ServiceFormEventBus.$on("reload:timestam-refresh", function () {
      this.getTimestamp();
    });
  },
};
</script>
